
import { JsonRpcProvider } from "ethers/providers";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { getContract } from "../lib/blockchain-helper";
import network from "../network";
import staticCoins from "./static-coins";
import Big from "bignumber.js";

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

function toWei(v: any, dp = 18) {
    return new Big(v.toString()).times(`1e${dp}`).dp(dp, Big.ROUND_DOWN);
}

function calcOutput(input: Big, inputReserve: Big, outputReserve: Big) {
    const x = input.times(0.998);
    return x.times(outputReserve).div(inputReserve.plus(x));
}

@Component({})
export default class Arbit extends Vue {
    loading = 0;
    provider = new JsonRpcProvider("https://mainnet-rpc.thundercore.com");

    liquidities: { id: string; major: Big; minor: Big }[] = [];
    symbols: string[] = [];

    symbolFilter = "";

    priceTo = "";
    swapAmount = 10;

    beforeDestroy() {
        this.cleanUp();
    }

    cleanUp() {
        this.provider.removeAllListeners();
    }

    mounted() {
        this.loadLiquidities();
    }

    toBig(v: any) {
        return new Big(v.toString());
    }

    liquiditiesFiltered() {
        if (this.symbolFilter) {
            const s = this.symbolFilter.toUpperCase();
            return this.liquidities.filter((v) => v.id.includes(s));
        } else {
            return this.liquidities;
        }
    }

    loadLiquidities() {
        ++this.loading;
        this.cleanUp();
        this.$nextTick(async function () {
            try {
                this.symbols = [];
                this.liquidities = [];

                for (const coin of staticCoins) {
                    if (coin.exchangeAddress) {
                        this.symbols.push(coin.id);

                        const contract = getContract(
                            network,
                            "Swapper",
                            this.provider,
                            coin.exchangeAddress
                        );

                        let checkingLiquidities: any = null;

                        const checkLiquidities = async () => {
                            const liquidity: [
                                Big,
                                Big
                            ] = await contract.currentLiquidity();

                            const tt = toEther(liquidity[0]);
                            const usd = toEther(
                                liquidity[1],
                                coin.decimalPlace
                            );

                            // console.log(coin.id, tt.toFormat(), usd.toFormat());

                            const index = this.liquidities.findIndex(
                                (v) => v.id == coin.id
                            );

                            if (index < 0) {
                                this.liquidities.push({
                                    id: coin.id,
                                    major: tt,
                                    minor: usd,
                                });
                            } else {
                                const l = this.liquidities[index];
                                l.major = tt;
                                l.minor = usd;
                                this.liquidities.splice(index, 1, l);
                            }
                        };

                        // checkingLiquidities = checkLiquidities();

                        this.provider.on(coin.exchangeAddress, async () => {
                            // console.log("liquidity changed-->", coin.id);
                            await checkingLiquidities;
                            checkingLiquidities = checkLiquidities();
                        });
                    }
                }
            } catch (error) {
                alert(error);
            } finally {
                --this.loading;
            }
        });
    }

    swapOutput(coinId: string) {
        const from = coinId;
        const to = this.priceTo;

        const liquidityFrom = this.liquidities.find((v) => v.id == from);
        const liquidityTo = this.liquidities.find((v) => v.id == to);

        if (!to || to == "TT") {
            if (liquidityFrom) {
                const out = calcOutput(
                    new Big(this.swapAmount),
                    liquidityFrom.minor,
                    liquidityFrom.major
                );

                return out;
            } else {
                return new Big(0);
            }
        }

        if (liquidityFrom && liquidityTo) {
            // swap to tt first..
            const out = calcOutput(
                new Big(this.swapAmount),
                liquidityFrom.minor,
                liquidityFrom.major
            );

            const out2 = calcOutput(out, liquidityTo.major, liquidityTo.minor);

            return out2;
        } else {
            return new Big(0);
        }
    }
}
